// components/HeaderBar.jsx
import { useEffect, useState, React} from 'react';
import './HeaderBar.css';
import { useModal } from './../Contexts/ModalContext';

function HeaderBar({ isWalletConnected, setIsWalletConnected, scrollToRef, aboutUsRef, bundleContainerRef,setPrimaryWallet }) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { showModal } = useModal();

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
          setIsScrolled(true);
      } else {
          setIsScrolled(false);
      }
  };

  // Listen for scroll events
  window.addEventListener('scroll', onScroll);
    
    const checkIfWalletIsConnected = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setIsWalletConnected(true);
            setPrimaryWallet(accounts[0])
          } else {
            setIsWalletConnected(false);
          }
        } catch (error) {
          console.error("Error checking wallet connection:", error);
        }
      }
    };
    checkIfWalletIsConnected();
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setPrimaryWallet(accounts[0])
      setIsWalletConnected(true);
      console.log(`Connected account: ${accounts[0]}`); // Use this to display or use the connected account
    } catch (error) {
      console.error('Error connecting to MetaMask', error);
    }
  };

  
  const handleModalInvoke = () => {
    console.log("Show modal")
    showModal(
      "Are you sure you want to perform this action?", // content
      "Confirm Action", // title
      () => console.log("Confirmed!") // confirm action
    );
  };


  

  return (
    <div className={`HeaderBar ${isScrolled ? 'scrolled' : ''}`}>
      <a href="/"><div className="logo"><img src="/logo.svg" height="60" /></div></a>
      <div className={`navigation ${isMenuVisible ? 'visible' : ''}`}>
        <a href="/about" className='no-text-decoration'><span>About Us</span></a>
        <a onClick={() => scrollToRef(bundleContainerRef)}><span>Explore</span></a>
			{ /*<a href="/token" className='no-text-decoration'><span>Token architecture</span></a>*/}
        
        <a href="/app" target='_blank'><button className="header-button">INVEST</button></a>
        {/* {!isWalletConnected && <button onClick={connectWallet} className="header-button">Connect Wallet</button>}
        {isWalletConnected && <a href="/app" target='_blank'><button className="header-button">INVEST</button></a>} */}
      </div>
      <div className="menu-icon" onClick={toggleMenu}>☰</div>
    </div>
  );
}

export default HeaderBar;
