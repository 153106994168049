/** AboutUs.jsx */
import React from 'react';
import styled from '@emotion/styled';
import "./AboutUs.css";

const FlexDiv = styled.div`  
display:flex;  
background: #081e32;
padding: 30px;
border-radius: 20px;
`;
const Container = styled.div`
  background: #061523;
   padding: 150px 10%;
`;

const Heading = styled.h1`
  color: var(--rabbitcha-gold);
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  line-height: 1.6;
  text-align: justify;
`;

const Bold = styled.b`
  font-weight: bold;
`;

const UnorderedList = styled.ul`
  
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AboutUs = () => (
  <>
  <picture>
      <source srcSet="/rabbit-gang-horiz.jpg" media="(min-width: 768px)" />
      <source srcSet="/rabbit-gang-vertic.jpg" media="(max-width: 767px)" />
      {/* Default image */}
      <img className='landing-img' src="/rabbit-gang-vertic.jpg" alt="Rabbit Gang" />
    </picture>
    
  <Container>
    <Heading>ABOUT US</Heading>
    <br/><br/>
    <div className='container-wrapper'>
      <img src="/rabbit_gold_face.svg" />
    <Section>
          <Paragraph>We are here to change the game of crypto investing. That sounds cliched. Indeed. But how we do this is anything but cliched. </Paragraph>
        <UnorderedList>
          <ListItem>
          Let’s start by acknowledging problems that face the crypto asset class and its investors and wannabe investors.
          </ListItem>
          <ListItem>
          It’s incredibly volatile with extreme <b>boom bust cycles</b> for tokens, investors and projects themselves. Pretty much nothing happens in crypto economy during bear markets.
          </ListItem>
          <ListItem>
          There is also a problem of <b>pseudo decentralization</b> in the asset class. Concentrated holdings and centralized mgmt / product teams make projects and their tokens. 
          </ListItem>
          <ListItem>
          The asset class is wonderfully liquid but the very channels of liquidity and <b>price discovery</b> (aka exchanges) contribute to feedback loops via insane leverage. And exchanges themselves can go bust.
          </ListItem>
          <ListItem>
          Given these cycles and feedback loops, there is hardly a mechanism of ensuring fair pricing for buyers and sellers beyond exchanges. The asset class has 2 built in solutions. 
a.	BTC’s <b>cost of mining</b> which works as a support mechanism but hardly does anything about volatility minimization.
b.	<b>Stablecoins</b> (unless they turn out to be luna) which fully address volatility but suffer from the biggest problem of fiat (continuous depreciation in inflation adjusted terms). 

          </ListItem>
          <ListItem>
          Let’s start by acknowledging problems that face the crypto asset class and its investors and wannabe investors.
          </ListItem>
        </UnorderedList>
      
    </Section>
    </div>
    <br/><br/><br/><br/><br/><br/>
    <div className='container-wrapper'>
    <Section>
      <Paragraph>RabBitCha.money is a deFi project that aims to address the following shortcomings </Paragraph>
      <UnorderedList>
        <ListItem>
        	Feedback loops and lack of fair value - <b>Redeemability</b> of all tokens we issue on fair value (no speculatory) working like BTC’s mining cost.
        </ListItem>
        <ListItem>
          Volatility - <b>Super diversified absolute return </b> portfolios, some of which can rival stablecoins in stability and BTC in growth potential.
        </ListItem>
        <ListItem>
          Pseudo Decentralization - Diversified developer ownership of the project and its raison d’etre.
        </ListItem>
        <ListItem>
         Ecosystem Failures – <b>Diversified exposures to exchanges and custodians.</b>
        </ListItem>

      </UnorderedList>
      
    {/* <Section> */}
      <Paragraph>
      RabBitCha.money comprises families of trading / investing products in the crypto asset class that offer alternatives to the volatile experience of HODLing. Each product is a diversified basket of algorithms that reflect a trading / investing style and that have a source of alpha that we’ve been able to identify. For instance, there is a long short basket that harnesses the behavior of reflexivity in this asset class. The idea of these baskets or algo Portfolios is to <b>avoid the steep downsides</b> and token specific risks that come with standalone algorithms.
      </Paragraph>
      <Paragraph>
      As a team, RabBitCha.money, is obsessed with algorithmically generating healthy returns for investors of all ticket sizes and risk appetites. RabBitCha.money has conceptualized some of the most robust ways to invest in the crypto asset class - safe against volatility, safe operationally and safe against ecosystem failures. In our book, preserving investor capital is our #1 mandate. If we preserve it during hard times, easy times will lend a hand in producing returns. Even the <b>riskiest of our products would still be safer than HODLing.</b>
      </Paragraph>
      
      </Section>
     
    {/* </Section> */}
    <img src="/rabbit_gold_face.svg" />
    </div>
    <br/><br/> <br/><br/>
    <Section>
      <Heading>The founding team</Heading>
      <Paragraph>
      The founding team is a team of <b>quant jocks and crypto geeks </b>who understand financial markets and their behavior and turn this understanding to math. The team comes with a collective experience of 40 years of generating alpha, another 30 years of building tech and another 20 years of playing with data and its predictive powers. With one eye on market inefficiencies (gateway to any alpha) and another on capital preservation (a rarity in the crypto asset class) this team is heel bent on making sure investor wealth charts a happier course than one that’s a victim of FUD and FOMO.
        
      </Paragraph>
      <Paragraph>
      The team has a track record of running systematic trading on sizeable capital in things traded on Wall street. The team has built intellectual property including pathbreaking finds life “risk on risk off classifier” – an algorithm that tells weather the mood on Wall Street is calm or turbulent. 
      </Paragraph>
      <div className='team-member' >
        <div className='team-member-photo'>
          <img src="/bharath.jpeg" /> 
        </div>
        <div className='team-member-description'>
          <p>Bharath handles research and disruptive economics at RabBitCha.money. Bharath is <b>a self-taught data science geek</b> with a penchant for financial markets. He has spent time in R&D, digital product development, ecosystem marketing and consulting. He has <b>invented</b>, among other things, an algorithmic investment / trading strategy that benefits from both risk on and risk off sentiments in the market. In the past, he significantly scaled <b>IBM developerworks</b> community and architected one of India’s biggest digital healthcare products – <b>Apollo 247.</b></p>
        </div>
      </div>
      <div className='team-member' >
        <div className='team-member-photo'>
          <img src="/balaji.jpg" /> 
        </div>
        <div className='team-member-description'>
          <p>Balaji leads product and technology at RabBitCha.money. With over 20 years of experience as a tech entrepreneur, he is a visionary known for pushing boundaries. He has a deep understanding in <b>AI, data science, IoT, and blockchain.</b> Some of his notable product creations include an AI-driven solution that enhances early-stage collection strategies for credit cards in retail banking, and "Agrobot," an innovative IoT product designed to revolutionize agricultural practices. His extensive background in R&D, digital product innovation, and strategic technology consulting positions him as a key leader in transforming innovative ideas into impactful technological solutions.</p>
        </div>
      </div>
      {/* <div className='team-member' >
        <div className='team-member-photo'>
          <img src="/sandeep.jpeg" /> 
        </div>
        <div className='team-member-description'>
          <p>Sandeep is one of the crypto pioneers and takes care of tokenomics and fund raise. Sandeep founded Kalamint and Reference Protocol in the NFT space and before that headed biz dev for Tezos India. Sandeep also happens to be a short term trader and advises on turning a human trader’s instincts to machine intelligence.</p>
        </div>
      </div> */}
      <div className='team-member' >
        <div className='team-member-photo'>
          <img src="/vikram.jpeg" /> 
        </div>
        <div className='team-member-description'>
          <p>Vikram is a seasoned leader in risk management who headed Bank of America Merryl Lynch’s risk management function. An <b>alumnus of MIT and Harvard</b>, he is passionate about <b>high sharpe</b> trading ideas and is currently the chairman of global wealth allocation ltd. He advices Rabbitcha.money on diversification and tail risk mitigation.</p>
        </div>
      </div>
      <div className='team-member' >
        <div className='team-member-photo'>
          <img src="/suraj.jpeg" /> 
        </div>
        <div className='team-member-description'>
          <p>Suraj is in charge of smart contracts and 360 degree customer experience at RabBitCha.money. He is a seasoned full stack developer with skills ranging from python to mondo DB to blockchain to node. Suraj has an extremely keen eye for business logic and executes in record time. This puts him at ease whatever the domain be. He has built robust <b>execution management systems</b> for algorithmic trading in the past.</p>
        </div>
      </div>
      
      <Paragraph>
      That said, it won’t be just us. We are working on building a community of algo developers to offer a truly decentralized and diversified experience to users. The capital the users bring to the table shouldn’t fail because of natural limits to the founding team’s cognitive abilities. 
      </Paragraph>
      
    </Section>
    <br/><br/>
    <Section>
      <Heading>Our Risk management credo</Heading>
      <Paragraph>
      Let’s start by understanding the importance of not losing excessive capital. If you have 100 dollars and lose 1% of it, you need to earn 1.01% to make it back. If you lose 5%, you need to earn about 5.25% to make it back. If you lose 10%, you need to earn 11%. If you lose 20% then 25% needs to be earned on what’s left to make it back. If you lose half your wealth, then you need to double what’s left just to get back to where you started from. If you lose 80%, then you need to make 500% on what’s left to get back on track. 
 
       </Paragraph>
       <Paragraph>
       That should explain why losing more than 20% to 25% pretty much makes the clawback impossible. We ensure this (contractually) wherever possible. Also ,the NAV of the products are protected contractually. i.e. they are redeemable by users AT WILL.  
        
      </Paragraph>
      <Paragraph>
      Key to risk management is diversification. That’s what we do 30% - across instruments, timeframes, alpha generating ideas, exchanges and custodians.  
      </Paragraph>
      <Paragraph>
      Compounding is the 8th wonder of the world but is also a double edged sword. Compounding needs to be actioned carefully and not at the drop of a hat. We do it the only way we know – mathematically optimal and commonsensical. 
      </Paragraph>
      <Paragraph>
      Leverage is, by the way, not a double edge sword exactly. It only cuts one way in the long term. It cuts the taker! Simply based on the mathematical truism explained in the beginning of this section. We avoid excessive leverage. Or leverage in real life will be between 1x (unlevered) and 5x.  We are ok to miss users interested in bigger leverage. We thank them for sparing our reputation!  However, keeping in mind exchange risks, we try and minimize the capital that is used as margin in the exchanges (not exceeding 20% of the AUM). The rest is deployed on low risk, yield farming and that’s diversified too. This is drawn down on only when required.      
      </Paragraph>
      <Paragraph>
      Lastly, the user is not compelled to trust us. We are but the seeds of a community of algo developers. So, the source of alpha will be diversified and decentralized too  
      </Paragraph>
      
      <UnorderedList>
        <ListItem>
          <Bold>Contractual NAV Protection:</Bold> The NAV of the products are protected contractually.
        </ListItem>
        <ListItem>
          <Bold>Diversification:</Bold> That’s what we do 30% - across instruments, timeframes, alpha
          generating ideas, exchanges and custodians.
        </ListItem>
        {/* ... other list items */}
      </UnorderedList>
    </Section>

    {/* ... more sections as needed */}

    <Section>
      <Heading>Join our Community</Heading>
      <Paragraph>
        That said, it won’t be just us. We are working on building a community of algo developers to offer a
        truly decentralized and diversified experience to users. {/* ... content */}
      </Paragraph>
    </Section>
  </Container>
  </>
);

export default AboutUs;
